/* src/components/Map/MapContainer.css */

.map-container {
  position: relative;
  width: 100%;
  height: 90%;
  overflow: hidden;
}

.view-bar {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}

/* Lower panel to hold info boxes, choose destination button and scene container bottom sheet */
.lower-panel {
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center; /* Center child components horizontally */
  gap: 10px;
  z-index: 998;
}

.info-box-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* The choose destination button styling */
.choose-destination-button-lower {
  background-color: #1e90ff; /* Primary Accent: Electric Blue */
  color: #ffffff; /* Primary Text */
  border: none;
  border-radius: 20px;
  padding: 12px 24px;
  font-size: 1em;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
  width: 80%;
}

.choose-destination-button-lower:hover {
  background-color: #1c86ee; /* Primary Accent Hover: Dodger Blue Darker */
}

/* Scene wrapper as bottom sheet */
.scene-wrapper {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #1e1e1e; /* Surface Background */
  border-radius: 12px;
  box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  width: 90vw;
  max-width: 600px;
  transition: transform 0.3s ease-in-out, height 0.3s ease-in-out;
}

.scene-wrapper.visible {
  transform: translateX(-50%) translateY(calc(25vh - 40px)); /* Shift down by scene-container's height */
  height: calc(25vh);
}

.scene-wrapper.minimized {
  transform: translateX(-50%) translateY(calc(25vh - 40px));
  height: 40px; /* Height of the header */
}

.scene-container-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: #1e1e1e; /* Surface Background */
  height: 40px;
  position: relative;
  z-index: 2;
}

.toggle-scene-button {
  background: none;
  border: none;
  color: #03dac6; /* Secondary Accent: Teal */
  font-size: 1em;
  cursor: pointer;
  text-decoration: underline;
  padding: 8px 16px;
  transition: color 0.3s ease;
  width: 100%;
  text-align: center;
}

.toggle-scene-button:hover {
  color: #029a94; /* Secondary Accent Hover: Teal Darker */
}

/* Scene container */
.scene-container {
  width: 100%;
  height: calc(25vh - 40px);
  background: #1e1e1e; /* Surface Background */
  position: relative;
  z-index: 1;
}

/* Adjust lower panel to account for scene wrapper */
.lower-panel {
  bottom: calc(25vh + 20px); /* Increased gap for better spacing */
}

/* Error and Loading Messages */
.error-message,
.loading-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #ffffff; /* Primary Text */
  background-color: #1e1e1e; /* Surface Background */
  font-size: 1.2em;
}
