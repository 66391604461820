/* src/components/Menu/MotionMenu.css */

.motion-menu-container {
  position: absolute; /* Ensures it overlays correctly within the bottom sheet */
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1000;
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out;
  height: 100%; /* Controlled by parent container (40vh) */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.motion-menu-container.visible {
  transform: translateY(0);
}

.fare-info {
  margin-bottom: 20px;
}

.fare-info h4 {
  margin-bottom: 10px;
}

.fare-info p {
  margin: 5px 0;
}

.continue-button {
  width: 100%;
  padding: 15px;
  background-color: #276ef1;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.continue-button:hover {
  background-color: #1e5bb8;
}
