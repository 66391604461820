/* Dark Theme Uber Style */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out; /* Add fade-in animation */
}

.modal-content {
  background: #1e1e1e; /* Dark background for the modal */
  color: #f5f5f5; /* Light text for contrast */
  padding: 20px;
  border-radius: 12px; /* Slightly more rounded */
  width: 400px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6); /* Stronger shadow */
  position: relative;
  animation: slideUp 0.3s ease-in-out; /* Add slide-up animation */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #f5f5f5; /* Light color for the icon */
  cursor: pointer;
  transition: transform 0.2s ease;
}

.close-button:hover {
  transform: scale(1.2); /* Slight zoom on hover */
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

button {
  padding: 10px 20px; /* More generous padding */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background: #276ef1; /* Uber-style blue */
  color: #fff; /* White text */
  font-weight: bold;
  display: flex;
  align-items: center; /* Align text and icons */
  justify-content: center;
  transition: background 0.3s ease, transform 0.2s ease;
}

button:hover {
  background: #1a5bb8; /* Darker blue on hover */
  transform: translateY(-2px); /* Subtle lift effect */
}

button svg {
  margin-right: 8px; /* Space between icon and text */
}

select {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #444; /* Darker border */
  border-radius: 8px;
  background: #2c2c2c; /* Darker background */
  color: #f5f5f5; /* Light text */
}

select:focus {
  outline: none;
  border-color: #276ef1; /* Blue border on focus */
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
