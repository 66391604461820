/* Header.css */

/* Header Container */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px; /* Increased padding for better spacing */
  width: 100%;
  height: 50px; /* Fixed height for the header */
  background-color: rgba(0, 0, 0, 0.85); /* Consistent dark background */
  z-index: 1000; /* Ensure the header stays above all other sections */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  box-sizing: border-box; /* Ensure padding doesn't affect total height */
  position: fixed; /* Fixed positioning */
  top: 0;
  left: 0;
}

/* Header Content Wrapper */
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  width: 100%;
}

/* Logo Section */
.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  max-height: 30px;
  object-fit: contain;
  transition: transform 0.3s ease; /* Add subtle animation for hover */
}

.logo:hover .logo-image {
  transform: scale(1.1); /* Slightly scale the logo on hover */
}

/* User Section */
.user-section {
  display: flex;
  align-items: center;
  position: relative;
}

.user-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* User Avatar Button */
.user-avatar-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #fff;
}

.user-avatar-button img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 7px;
}

.user-name {
  font-size: 0.9rem;
  color: #fff;
}

/* Dropdown Menu */
.dropdown-menu {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s, transform 0.3s ease-in-out;
  z-index: 100; /* Lower than header's z-index */
  pointer-events: none;
}

.dropdown-menu.show {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.dropdown-menu li {
  padding: 10px 20px;
  font-size: 0.9rem;
  color: #333;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

/* Sign-In and Get Started Buttons */
.signin-button,
.get-started-button {
  background-color: #e7e8ec; /* Updated sign-in button color */
  color: #333; /* Dark text for contrast */
  font-family: "Roboto", Arial, sans-serif;
  font-size: 0.6rem; /* 60% of original font size (16px -> 9.6px) */
  font-weight: bold;
  height: 24px; /* 60% of original height (40px -> 24px) */
  padding: 0 9.6px; /* 60% of original padding (16px -> 9.6px) */
  border: none;
  border-radius: 12px; /* Maintains pill shape */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: auto; /* Adjust width dynamically based on text */
  white-space: nowrap; /* Prevent text wrapping */
}

/* Hover Effects */
.signin-button:hover,
.get-started-button:hover {
  background-color: #d6d7da; /* Slightly lighter shade for hover */
}

.signin-button:active,
.get-started-button:active {
  transform: scale(0.95);
}

/* Disabled State */
.signin-button:disabled {
  background-color: #cfcfcf;
  color: #8e8e8e;
  cursor: not-allowed;
}

/* Loading Spinner */
.loading {
  text-align: center;
  font-size: 1.2rem;
  margin-top: 20px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-top-color: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Button Wrapper */
.button-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}
