/* src/components/Scene/SceneContainer.css */

.scene-container {
  width: 100%;
  height: 100%;
  position: relative;
  background: #fff;
}

.scene-map {
  width: 100%;
  height: 100%;
}
