/* src/components/Map/ViewBar.css */

.view-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(31, 41, 55, 0.95); /* Lightened dark gray */
  padding: 8px 16px;
  border-radius: 50px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.9em;
  font-weight: 400;
  width: 90vw;
  max-width: 600px;
  z-index: 10;
}

.view-bar-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.view-bar-title-pill {
  background-color: #1f2937; /* Surface color */
  border-radius: 9999px;
  padding: 4px 12px;
  margin: 0 16px;
  color: #e5e5e5; /* Off-white text */
}

.view-bar-title-pill h2 {
  margin: 0;
  font-size: 1em;
  font-weight: 400;
  color: #e5e5e5; /* Ensure title text is off-white */
}

.view-bar-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.departure-info,
.arrival-info {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #e5e5e5; /* Ensure info text is off-white */
}

.view-bar-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}

.view-all-stations-button {
  background-color: transparent; /* No background */
  border: none; /* No border */
  color: #6ee7b7; /* Soft cyan */
  padding: 0.6em; /* Increased padding for better touch target */
  border-radius: 0.5em; /* Rounded corners */
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
}

.view-all-stations-button:hover {
  background-color: #1e3a8a; /* Deep blue on hover */
  color: #fff; /* White text on hover */
}

/* Media Queries */
@media (max-width: 767px) {
  .view-bar {
    flex-direction: row;
    align-items: center;
  }

  .view-bar-info {
    margin-top: 0.5em;
  }
}
