/* src/components/Map/Button.css */

/* Locate Me Button */
.locate-me-button {
  background-color: #2171ec; /* Blue background */
  border: none;
  color: #fff; /* White icon color */
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.locate-me-button:hover {
  background-color: #195db7; /* Darker blue on hover */
}

.locate-me-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(33, 113, 236, 0.5); /* Focus ring */
}

/* Button.css */

.spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.locate-me-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
