/* src/App.css */
/* Base layout - No scrolling */
html, body, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

/* Hide the Google Maps alpha banner */
div[role="region"][aria-label*="alpha channel"] {
  display: none !important;
}

/* App container with fixed heights */
.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Fixed header */
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #000000;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  z-index: 1000;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Main content with calculated height */
.main-content {
  height: calc(100% - 90px); /* Subtract header (50px) + footer (40px) */
  margin-top: 50px; /* Header height */
  display: flex;
  flex-direction: column;
  padding: 2.5px;
  box-sizing: border-box;
  gap: 1.5px;
  position: relative;
  overflow: hidden;
}

/* Fixed footer */
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px; /* Using the 30px height from Footer.css */
  background-color: var(--primary-black);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  box-sizing: border-box;
}

/* Map container */
.map-container {
  width: 100%;
  height: 100%;
  border: 1px solid #555555;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

/* Loading Screen */
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #ffffff;
  font-size: 1.5rem;
}

/* Motion menu */
.motion-menu {
  width: 100%;
  height: 35%;
  background-color: var(--primary-black);
  border-top: 1px solid #555555;
  position: absolute;
  bottom: 40px;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.motion-menu-wrapper {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out;
  z-index: 999;
}

.motion-menu-wrapper.visible {
  transform: translateY(0);
}
