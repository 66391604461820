/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  background-color: #121212; /* Dark background */
  color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Additional global styles can be added here */
